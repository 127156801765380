/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import * as Accordion from '@radix-ui/react-accordion'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import styled from 'styled-components'
import { Shield } from 'react-feather'

import {
	TokenStandard,
	useApprovalEvents,
	useApprovalForAllEvents,
	useTransferEvents,
} from '../utils/hooks'
import '../main.css'
import Token from '../components/Token'
import { isEthAddress } from '../utils'

const Toggle = styled(ToggleGroup.Root)`
	display: flex;

	button {
		appearance: none;
		border: none;
		background: none;
		background-color: white;
		border-radius: 0;
		border-right: 1px solid #ddd;

		&[data-state='on'] {
			background-color: #ddd;
		}

		&:first-child {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		&:last-child {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			border-right: none;
		}
	}
`

function App() {
	const { address: accountAddress } = useAccount()
	const [standard, setStandard] = useState<TokenStandard>(TokenStandard.erc20)
	const [address, setAddress] = useState<string | undefined>(undefined)

	const { data: transferEvents } = useTransferEvents({
		address,
		standard,
		enabled: isEthAddress(address),
	})

	const { data: approvalForAllEvents = [] } = useApprovalForAllEvents({
		address,
		standard,
		enabled: standard === TokenStandard.erc721 && isEthAddress(address),
	})

	const { data: approvalEvents = [] } = useApprovalEvents({
		address,
		standard,
		enabled: standard === TokenStandard.erc20 && isEthAddress(address),
	})

	useEffect(() => {
		if (address === undefined && accountAddress) {
			setAddress(accountAddress)
		}
	}, [address, accountAddress])

	const approvals = [...approvalForAllEvents, ...approvalEvents]

	const uniqueTokenAddressSet = new Set(
		transferEvents?.map((event: any) => event.address) ?? [],
	)
	const uniqueTokenAddress = Array.from(uniqueTokenAddressSet) as string[]

	return (
		<div style={{ maxWidth: 900, margin: '0 auto', padding: 10 }}>
			<header>
				<a href="https://github.com">GitHub</a>
				<a href="https://github.com">They Xolo</a>
			</header>
			<h1
				style={{
					fontSize: '2rem',
					textAlign: 'center',
					fontWeight: '900',
					marginBottom: 16,
					letterSpacing: -2,
					fontStyle: 'italic',
				}}
			>
				<Shield strokeWidth={4} />
				REVOKE
			</h1>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 8,
					marginBottom: 32,
				}}
			>
				<input
					type="text"
					placeholder="0x..."
					value={address ?? ''}
					onChange={(e) => setAddress(e.target.value)}
					style={{
						// 32 chars
						minWidth: 410,
						textAlign: 'center',
					}}
				/>
				<Toggle
					type="single"
					defaultValue={TokenStandard.erc20}
					aria-label="Standard"
					value={standard}
					onValueChange={(value) => {
						if (value && value !== standard) setStandard(value as TokenStandard)
					}}
				>
					<ToggleGroup.Item value={TokenStandard.erc20} aria-label="Use ERC20">
						Tokens
					</ToggleGroup.Item>
					<ToggleGroup.Item
						value={TokenStandard.erc721}
						aria-label="Use ERC721"
					>
						NFTs
					</ToggleGroup.Item>
				</Toggle>
			</div>

			<Accordion.Root
				style={{
					gap: 8,
					display: 'flex',
					flexDirection: 'column',
					marginBottom: '25vh',
				}}
				type="multiple"
			>
				{uniqueTokenAddress.map((tokenAddress) => (
					<Token
						key={tokenAddress}
						standard={standard}
						address={tokenAddress}
						approvals={approvals.filter(
							(event: any) => event.address === tokenAddress,
						)}
						account={address as any}
					/>
				))}
			</Accordion.Root>
		</div>
	)
}

export default App
