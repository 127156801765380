import { BigNumber } from 'ethers'
import styled from 'styled-components'

import { formatTokenAmount } from '../../utils'

const CONTRACT_NAME_MAP = {
	['0xf5e4c497768b13978d256e3616ec47f37a82b772']: 'OpenSea (old)',
	['0x1e0049783f008a0085193e00003d00cd54003c71']: 'OpenSea: Conduit',
	['0x68b3465833fb72a70ecdf485e0e4c7bd8665fc45']: 'Uniswap V3: Router 2',
	['0x401f6c983ea34274ec46f84d70b31c151321188b']:
		'Polygon (Matic): Plasma Bridge',
	['0x5d3a536e4d6dbd6114cc1ead35777bab948e3643']: 'Compound: cDAI Token',
	['0x7a250d5630b4cf539739df2c5dacb4c659f2488d']: 'Uniswap V2: Router 2',
	['0xe5c783ee536cf5e63e792988335c4255169be4e1']:
		'OpenSea: Wyvern Token Transfer Proxy',
	['0x66329fdd4042928bfcab60b179e1538d56eeeeee']: 'EPNSCoreProxy',
} as {
	[address: string]: string
}

const Container = styled.li`
	list-style: none;
`

function Approval({
	approval,
	spender: spenderAddress,
	isUnlimitedAllowance,
	decimals,
	allowance,
}: {
	approval: any
	spender: string
	isUnlimitedAllowance: boolean
	decimals: number
	allowance?: BigNumber
}) {
	return (
		<Container key={JSON.stringify(approval)}>
			<a
				style={{ color: 'inherit' }}
				href={`https://etherscan.io/address/${spenderAddress}`}
			>
				{CONTRACT_NAME_MAP[spenderAddress] ?? spenderAddress}
			</a>
			<p>
				{isUnlimitedAllowance
					? 'unlimited'
					: allowance
					? formatTokenAmount(allowance, decimals).toString()
					: 'unknown'}
			</p>
			<button>Revoke</button>
		</Container>
	)
}

export default Approval
