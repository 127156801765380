import styled from 'styled-components'
import * as Accordion from '@radix-ui/react-accordion'

export const Trigger = styled(Accordion.Trigger)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	border: none;
	border-radius: 16px;
	padding: 8px;

	&[aria-expanded='true'] {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
`
