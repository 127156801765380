/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	useProvider,
	useBlockNumber,
	erc721ABI,
	useQuery,
	erc20ABI,
} from 'wagmi'
import { hexZeroPad, Interface } from 'ethers/lib/utils'

import { isEthAddress } from '.'

const erc721Interface = new Interface(erc721ABI)
const erc20Interface = new Interface(erc20ABI)

type EventTopic = 'Transfer' | 'Approval' | 'ApprovalForAll'

function getTopicsFilter(eventTopic: EventTopic, address?: string) {
	if (eventTopic === 'Approval' || eventTopic === 'ApprovalForAll') {
		return [
			eventTopic === 'Approval'
				? erc20Interface.getEventTopic(eventTopic)
				: erc721Interface.getEventTopic(eventTopic),
			// eslint-disable-next-line no-magic-numbers
			isEthAddress(address) ? hexZeroPad(address!, 32) : null,
			null,
		]
	}

	return [
		erc721Interface.getEventTopic(eventTopic),
		null,
		// eslint-disable-next-line no-magic-numbers
		isEthAddress(address) ? hexZeroPad(address!, 32) : null,
	]
}

type UseEventParams = {
	address?: string
	fromBlock?: number
	toBlock?: number
	standard: TokenStandard
	enabled?: boolean
}

export enum TokenStandard {
	erc20 = 'ERC20',
	erc721 = 'ERC721',
	erc1155 = 'ERC1155',
}

export function useEvents(params: UseEventParams & { eventName: EventTopic }) {
	const {
		address,
		toBlock,
		standard,
		eventName,
		fromBlock = 0,
		enabled = true,
	} = params

	const provider = useProvider()
	const { data: currentBlock } = useBlockNumber({ enabled: !toBlock })

	const filter = {
		topics: getTopicsFilter(eventName, address),
		fromBlock,
		toBlock: currentBlock ?? toBlock,
	}

	return useQuery<unknown, unknown, any, any>(
		['transferEvents', params],
		async () => {
			const result = await provider.getLogs(filter)

			if (!result) return []

			return result.filter((event: any) =>
				eventName === 'Transfer'
					? // eslint-disable-next-line no-magic-numbers
					  event.topics?.length === (standard === TokenStandard.erc20 ? 3 : 4)
					: true,
			)
		},
		{ enabled: enabled && Boolean(address) },
	)
}

export function useTransferEvents(params: UseEventParams) {
	return useEvents({ ...params, eventName: 'Transfer' })
}

export function useApprovalEvents(params: UseEventParams) {
	return useEvents({ ...params, eventName: 'Approval' })
}

export function useApprovalForAllEvents(params: UseEventParams) {
	return useEvents({ ...params, eventName: 'ApprovalForAll' })
}
