import React from 'react'
import ReactDOM from 'react-dom/client'
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'

import App from './containers/App'
import './index.css'

const { provider } = configureChains(
	[chain.mainnet],
	[
		infuraProvider({ apiKey: import.meta.env.VITE_INFURA_API_KEY }),
		publicProvider(),
	],
)

const client = createClient({
	autoConnect: true,
	provider,
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<WagmiConfig client={client}>
			<App />
		</WagmiConfig>
	</React.StrictMode>,
)
