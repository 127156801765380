import { formatUnits } from 'ethers/lib/utils.js'
import { BigNumber } from 'ethers'

export function formatTokenAmount(amount: BigNumber, decimals: number): string {
	if (!decimals) return amount.toString()
	return formatUnits(amount, decimals).replace(/\.0+$/, '')
}

export function isEthAddress(address: string | undefined) {
	return /^0x[a-fA-F0-9]{40}$/.test(address ?? '')
}
