/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContractReads, erc20ABI } from 'wagmi'
import { getAddress } from 'ethers/lib/utils'
import * as Accordion from '@radix-ui/react-accordion'
import * as Avatar from '@radix-ui/react-avatar'
import { BigNumber } from 'ethers'
import { ChevronDown } from 'react-feather'

import { TokenStandard } from '../../utils/hooks'
import { formatTokenAmount } from '../../utils'
import Approval from '../Approval'

import { Trigger } from './styled'

function Token({
	address: tokenAddress,
	account,
	standard,
	approvals,
}: {
	address: string
	account: `0x${string}`
	standard: TokenStandard
	approvals: any[]
}) {
	const contract = {
		abi: erc20ABI,
		address: tokenAddress,
	} as const

	const isErc20 = standard === 'ERC20'

	let contracts = [
		{
			...contract,
			functionName: 'name',
			args: [] as string[],
		},
		{
			...contract,
			functionName: 'totalSupply',
			args: [],
		},
		{
			...contract,
			functionName: 'balanceOf',
			args: [account],
		},
	]

	if (isErc20) {
		contracts = contracts.concat([
			{
				...contract,
				functionName: 'symbol',
				args: [],
			},
			{
				...contract,
				functionName: 'decimals',
				args: [],
			},
		])
	}

	const { data } = useContractReads<any, any, any>({
		contracts,
		allowFailure: true,
	})

	if (!data) return null

	if (data[4] && data[4].toString() === '0') return null

	const totalSupply = data[1] as BigNumber
	const tokenName = (isErc20 ? data[3] : data[0]) as string
	const balanceOf = data?.[2] as BigNumber
	const decimals = data[4] as number

	if (!isErc20 && data[2]?.toString() === '0') {
		return null
	}

	const checksumAddress = getAddress(tokenAddress)
	return (
		<Accordion.Item value={tokenName}>
			<Trigger disabled={approvals.length === 0}>
				<p
					style={{ display: 'flex', alignItems: 'center', gap: 4 }}
					title={tokenAddress}
				>
					<Avatar.Root
						style={{
							width: 30,
							height: 30,
							borderRadius: '100%',
							overflow: 'hidden',
						}}
					>
						{Boolean(isErc20) && (
							<Avatar.Image
								src={`https://raw.githubusercontent.com/rainbow-me/assets/master/blockchains/ethereum/assets/${checksumAddress}/logo.png`}
								alt={tokenName}
								style={{ width: '100%', height: '100%' }}
							/>
						)}
						<Avatar.Fallback
							style={{
								width: '100%',
								height: '100%',
								backgroundColor: 'gray',
								fontWeight: '500',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							className="AvatarFallback"
							delayMs={600}
						>
							{tokenName?.[0]}
							{tokenName?.[1]}
						</Avatar.Fallback>
					</Avatar.Root>
					<b>{tokenName}</b>
					<span>
						{isErc20
							? formatTokenAmount(balanceOf, decimals)
							: balanceOf.toString()}
					</span>
				</p>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p>
						{approvals.length
							? `${approvals.length} approvals`
							: 'No approvals'}
					</p>
					<ChevronDown />
				</div>
			</Trigger>
			<Accordion.Content>
				<div
					style={{
						backgroundColor: '#fff',
						padding: 16,
						borderRadius: '0 0 16px 16px',
						display: 'grid',
						gridTemplateColumns: 'repeat(3, 1fr)',
						gap: 16,
					}}
				>
					{approvals.map((approval) => {
						const {
							data: allowanceString,
							topics: [, , spender],
						} = approval
						const spenderAddress = spender.replace(/^0x0+/, '0x')

						const isUnlimitedAllowance =
							allowanceString ===
								'0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff' ||
							allowanceString === '0x'
								? false
								: BigNumber.from(allowanceString).gt(totalSupply)

						return (
							<Approval
								key={JSON.stringify(approval)}
								allowance={
									isUnlimitedAllowance
										? undefined
										: allowanceString === '0x'
										? BigNumber.from('0')
										: BigNumber.from(allowanceString)
								}
								decimals={decimals}
								approval={approval}
								isUnlimitedAllowance={isUnlimitedAllowance}
								spender={spenderAddress}
							/>
						)
					})}
				</div>
			</Accordion.Content>
		</Accordion.Item>
	)
}

export default Token
